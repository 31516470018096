<template>
  <div class="consulting">
    <div class="consulting-left">
      <div class="second-navlist">
        <div
          class="second-items"
          :class="typelisttext == item.title ? 'active-second-items' : null"
          v-for="(item, index) in Consultationclasslist"
          @click="changebtn(item)"
          :key="index"
          @mouseleave="fadeout(item)"
          @mouseenter="fadein(item)"
        >
          {{ item.title }}
          <!-- <i class="iconfont icon-xiajiantou"></i> -->
        </div>
        <!-- <div class="second-show-box" v-show="typelistflag" @mouseleave="boxfadeout" @mouseenter="boxfadein">
          <div v-for="item in 10">
            行业新闻
          </div>
        </div> -->
      </div>
      <div class="main-body">
        <div
          class="main-body-item"
          v-for="(item, index) in Consultationlist"
          :key="index"
          @click="godetails(item, 2)"
        >
          <div class="img-box">
            <img class="left-img" :src="global.imgUrl + (item.imgUrl==''?'/upload/202403/18/ef16ebe2-4d42-47c7-b4e9-e1a9cc6df388.jpg':item.imgUrl)" alt="" />
          </div>
          <div class="items-right">
            <p class="title" >
              {{ item.title }}
            </p>
            <p>{{ item.zhaiyao }}</p>
            <div class="items-right-bottom">
              <div>
                <img class="iconfont" :src="imgIcon.imgPlace" />
                <!-- <i class="iconfont icon-leftfont-104"></i> -->
                <div v-if="item.author">{{ item.author }}</div>
                <div v-else>德泰中研（转载）</div>
              </div>
              <div>
                <!-- <i class="iconfont icon-naozhong2"></i> -->
                <img class="iconfont" :src="imgIcon.imgTime" />
                {{ item.addTime.slice(0, 10) }}
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="consulting-right">
      <div class="consulting-header">
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-zu339"></use>
          </svg>
          推荐资讯
        </div>
        <!-- <div>查看全部</div> -->
      </div>
      <div
        class="consulting-items"
        v-for="(item, index) in hotconsultlist"
        :key="index"
        @click="godetails(item, 2)"
      >
        <div class="img-box">
          <img :src="global.imgUrl + item.ImgUrl" alt="" />
          <div class="date-message">
            <div>{{ item.Title }}</div>
            <!--<br />
            <div class="date-box">
              <div v-if="item.Author">{{ item.Author }}</div>
              <div v-else>德泰中研（转载）</div>
              <div>{{ item.AddTime.slice(0, 10) }}</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  getConsultationlist,
  getConsultationclass,
  getConsultationcategory,
  gethotconsultinglist,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "consulting",
  data() {
    return {
      secondnavlist: [
        { title: "全部", id: "0" },
        { title: "行业新闻", id: "1" },
        { title: "独家专题", id: "2" },
        { title: "行业动态", id: "3" },
        { title: "行业观点", id: "4" },
      ],
      currentPage: 1,
      typelistflag: false,
      typelisttext: "全部",
      Consultationlist: [],
      Consultationclasslist: [],
      total: 0,
      hotconsultlist: [],
      imgIcon: imgObj,
    };
  },
  methods: {
    handleCurrentChange(e) {
      getConsultationlist(e).then((res) => {
        this.Consultationlist = res.data;
      });
      console.log(e);
    },
    handleSizeChange(e) {
      console.log(e);
    },
    fadeout(item) {
      // console.log(item,"移出");
      this.typelistflag = false;
    },
    fadein(item) {
      // console.log(item,"移入");
      this.typelistflag = true;
    },
    boxfadein() {
      this.typelistflag = true;
    },
    boxfadeout() {
      this.typelistflag = false;
    },
    changebtn(item) {
      if (item.title !== "全部") {
        // this.getData(item.id)
        getConsultationcategory(item.id).then((res) => {
          this.Consultationlist = res.data;
            this.total = parseInt(
          res.headers["x-pagination"].split(",")[0].split(":")[1]
        );
        });
      } else {
        // this.getData(0)
        getConsultationlist(1).then((res) => {
          this.Consultationlist = res.data;

            this.total = parseInt(
          res.headers["x-pagination"].split(",")[0].split(":")[1]
        );
        });
      }
      this.typelisttext = item.title;
    },
    godetails(item, type) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`)
      let routeData = this.$router.resolve({
        name: "DetailsPage",
        query: {
          id: item.id || item.Id,
          type,
           title:item.title || item.Title
        },
      });
      window.open(routeData.href, "_blank");
    },
    // async getData(classifyId) {
    //   let res = await axios.get(
    //     `https://mcnki.obs.cn-east-2.myhuaweicloud.com/js/74-${classifyId}-0.js`
    //   );
    //   this.Consultationlist = res.data;
    //   this.total = this.Consultationlist.length;
    // },
    start() {
      getConsultationlist(1).then((res) => {
        console.log("资讯列表", res);
        this.Consultationlist = res.data; //总长度
        this.total = parseInt(
          res.headers["x-pagination"].split(",")[0].split(":")[1]
        );
        console.log(this.total);
        console.log(res);
        // this.total = this.Consultationlist.length
      });
      getConsultationclass().then((res) => {
        console.log("资讯分类", res);
        this.Consultationclasslist = res.data;
      });
    },
    async getHotSulatationList() {
      let res = await axios.get(
        "https://mcnki.obs.cn-east-2.myhuaweicloud.com/js/184-0-36.js"
      );
      this.hotconsultlist = res.data;
      //   gethotconsultinglist().then((res) => {
      //   console.log("推荐资讯", res);
      //   this.hotconsultlist = res.data;
      // });
    },
  },
  created() {
    // this.getData(0);
    this.getHotSulatationList();
  },
  mounted() {
    this.start();
    // this.getHotSulatationList()
  },
};
</script>

<style lang="less" scoped>
.consulting {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  .consulting-left {
    width: 100%;
    margin-right: 20px;
    .second-navlist {
      background: white;
      display: flex;
      position: relative;
      border-bottom: 1px solid #e9e9e9;
      .second-show-box {
        position: absolute;
        top: 58px;
        width: 100%;
        background: white;
        z-index: 999;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        border-bottom: 1px solid #f3f3f3;
        border-top: 1px solid #eaeaea;
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 10%);
        div {
          margin: 0 10px;
          padding: 20px;
          color: #999;
          &:hover {
            color: black;
            cursor: pointer;
          }
        }
      }
      .active-second-items {
        background: #314483;
        color: #f5f6f6;
        // font-weight: bold;
        border-radius:3px
        
      }
      
      .second-items {
        padding: 8px 15px;
        margin: 10px 10px;
        font-size: 14px;
        cursor: pointer;
        .icon-xiajiantou {
          margin-left: 5px;
        }
         &:hover {
         background: #314483;
         color: #f5f6f6;
            cursor: pointer;
        border-radius:3px
          }
      }
    }
    .main-body {
      margin: 20px 0;
      .main-body-item {
        background: white;
        height: 144px;
        padding: 30px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
       &:hover {
          cursor: pointer;
          background: #FAFAFA;
          
        }
        .img-box {
          overflow: hidden;
          width: 308px;
        }
        .left-img {
          width: 100%;
          height: 100%;
          margin-right: 20px;
          transition: 1s;
          cursor: pointer;
          &:hover {
            scale: 1.3;
          }
        }
        .items-right {
          width: 100%;
          position: relative;
          margin-left: 20px;
          .title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
          .title:hover{
color: #3D55A7
          }
          .items-right-bottom {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #a9a9a9;
            div {
              display: flex;
            }
            .iconfont {
              color: #848484;
              margin-right: 10px;
              width: 16px;
              height: 16px;
            }
          }
          p {
            margin: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .title {
            font-size: 20px;
            font-weight: 600;
          }
          p:nth-child(2) {
            color: #848484;
            font-size: 14px;
            margin-top: 25px;
            overflow: hidden;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
      .el-pagination {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        /deep/ li {
          margin: 0 8px !important;
          min-width: 36px !important;
          height: 34px;
          line-height: 36px;
        }
        /deep/ .active {
          color: white;
          background: #314483;
        }
        /deep/ li:hover {
          color: white;
          background: #314483;
        }
        /deep/ button {
          margin: 0 8px !important;
          height: 34px;
          line-height: 36px;
        }
        /deep/ .btn-next {
          padding: 0;
        }
        /deep/ .btn-prev {
          padding: 0;
        }
        /deep/ button:hover {
          color: white;
          background: #314483;
        }
        /deep/ span {
          margin-right: 30px;
          line-height: 36px;
        }
      }
    }
  }
  .consulting-right {
    width: 40%;
    .consulting-header {
      padding: 20px 20px;
      background: white;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .icon {
        width: 15px;
        height: 15px;
      }
      div:nth-child(1) {
        font-size: 16px;
        font-weight: 400;
      }
      div:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: #9a9a9a;
      }
    }
    .consulting-items {
      padding: 20px;
      background: white;
      .img-box {
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
          cursor: pointer;
          width: 100%;
          height: 196px;
          transition: 1s;
        }
        img:hover {
            scale: 1.3;
          }
      }
      .date-message {
        bottom: 0px;
        width: 88%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        color: white;
        position: absolute;
        padding: 15px 20px;
        cursor: pointer;
        font-size: 14px;
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*禁止换行*/
        text-overflow: ellipsis; /*省略号*/
        .date-box {
          display: flex;
          justify-content: space-between;
          opacity: 0.63;
          padding-bottom: 5px;
          .iconfont {
            font-size: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>